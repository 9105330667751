import React from 'react'
import PropTypes from 'prop-types'
import '../index.css'
import { Link } from 'react-router-dom';
export default function NavBar(props) {
    return (
        <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">{props.title}</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link S" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">{props.about}</Link>
                        </li>
                    </ul>
                    <div className={`form-check form-switch  container px-5 text-${props.mode === 'light' ? 'dark' : 'light'}`}>
                        <input className="form-check-input" onClick={props.toggleMode} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{props.text}</label>
                    </div>
                    <div className="d-flex">
                        <div className="bg-primary rounded mx-2" onClick={()=>props.toggleColor('primary')} style={{height:'25px' , width:'25px'}}></div>
                        <div className="bg-danger rounded mx-2" onClick={()=>props.toggleColor('danger')} style={{height:'25px' , width:'25px'}}></div>
                        <div className="bg-warning rounded mx-2" onClick={()=>props.toggleColor('warning')} style={{height:'25px' , width:'25px'}}></div>
                        <div className="bg-success rounded mx-2" onClick={()=>props.toggleColor('success')} style={{height:'25px' , width:'25px'}}></div>
                    </div>
                    <form className="d-flex" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form>

                </div>
            </div>
        </nav >
    )
}
NavBar.propTypes = {
    title: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired
}
NavBar.defaultProps = {
    title: 'Title',
    about: 'About'
}